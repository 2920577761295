<template>
<div>
    <MyTechnicalList></MyTechnicalList>
</div>
</template>

<script>
// این ویو برای صفحه تحلیل های کاربر در پروفایل کاربر می باشد
import MyTechnicalList from '@/components/Profile/Technical.vue'

export default {
    name: 'ProfileTechnicalView',
    components: {
        MyTechnicalList
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
}
</script>
